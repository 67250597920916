import * as R from 'ramda';
import { createReducer } from 'redux-act';
import { $all, $set, $toggle } from 'plow-js';
// common
import * as A from './actions';
// features
import { receivedSwitchBranchSuccess } from '../features/branch/actions';
// helpers/constants
import * as GC from '../constants';
//////////////////////////////////////////////////

const initialState = {
  submitLoading: false,
  documentTemplates: {},
  initialDataLoaded: false,
  initialDataLoading: false,
  showDriverOnboardingTab: null,
  mailServiceIntegrationType: null,
  connectedToMailIntegrationEmails: {},
  connectionToMailIntegrationRequired: false,
  initialSplashScreenSetting: { isLoaded: false },
  // googleApiKey: 'AIzaSyB9Vj08tH1omYsW5k8GDWAY5PtfrE_OxAk',
};

const toggleSubmitLoading = (state: Object) => $toggle('submitLoading', state);

const setGoogleApiKey = (state: Object, googleApiKey: string) => (
  $set('googleApiKey', googleApiKey, state)
);

const initialDataLoadRequest = (state: Object) => (
  $all(
    $set('initialDataLoaded', false),
    $set('initialDataLoading', true),
    state,
  )
);

const initialDataLoadFail = (state: Object) => (
  $all(
    $set('initialDataLoaded', true),
    $set('initialDataLoading', false),
    state,
  )
);

const initialDataLoadSuccess = (state: Object) => (
  $all(
    $set('initialDataLoaded', true),
    $set('initialDataLoading', false),
    state,
  )
);

const initialSplashScreenLoadFail = (state: Object) => (
  $set('initialSplashScreenSetting', { isLoaded: true }, state)
);

const initialSplashScreenLoadSuccess = (state: Object, data: Object) => (
  $set('initialSplashScreenSetting', { ...data, isLoaded: true }, state)
);

const getDocumentTemplateSuccess = (state: Object, data: Object) => (
  $set('documentTemplates', R.groupBy(R.prop(GC.FIELD_PRINTABLE_SECTION), data), state)
);

const setConnectedToMailIntegrationEmails = (state: Object, data: Object) => (
  $set('connectedToMailIntegrationEmails', data, state)
);

const disconnectFromMailIntegrationSuccess = (state: Object, email: string) => (
  $set('connectedToMailIntegrationEmails', R.dissoc(email, state.connectedToMailIntegrationEmails), state)
);

const setConnectionToMailIntegrationRequired = (state: Object, data: Object) => (
  $set('connectionToMailIntegrationRequired', data, state)
);

const setMailServiceIntegrationType = (state: Object, data: Object) => (
  $set('mailServiceIntegrationType', data, state)
);

const getShowDriverOnboardingTabSuccess = (state: Object, showReportTab: boolean) => (
  $set('showDriverOnboardingTab', showReportTab, state)
);

const hideDriverOnboardingTabOnSwitchBrach = (state: Object) => $set('showDriverOnboardingTab', null, state);

export const global = createReducer({
  [A.setGoogleApiKey]: setGoogleApiKey,
  [A.toggleSubmitLoading]: toggleSubmitLoading,
  [A.initialDataLoadFail]: initialDataLoadFail,
  [A.initialDataLoadRequest]: initialDataLoadRequest,
  [A.initialDataLoadSuccess]: initialDataLoadSuccess,
  [A.getDocumentTemplateSuccess]: getDocumentTemplateSuccess,
  [A.initialSplashScreenLoadFail]: initialSplashScreenLoadFail,
  [A.setMailServiceIntegrationType]: setMailServiceIntegrationType,
  [A.initialSplashScreenLoadSuccess]: initialSplashScreenLoadSuccess,
  [receivedSwitchBranchSuccess]: hideDriverOnboardingTabOnSwitchBrach,
  [A.getShowDriverOnboardingTabSuccess]: getShowDriverOnboardingTabSuccess,
  [A.setConnectedToMailIntegrationEmails]: setConnectedToMailIntegrationEmails,
  [A.disconnectFromMailIntegrationSuccess]: disconnectFromMailIntegrationSuccess,
  [A.setConnectionToMailIntegrationRequired]: setConnectionToMailIntegrationRequired,
}, initialState);

export default global;
